import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import Layout from "../components/layout"
import Footer from "../components/Footer"
import ComponentSelector from "../components/ComponentSelector"

export default function Page({ data, pageContext }) {
  const { components, metadata} = data.page.nodes[0]
  const { title, showFooterForm } = pageContext

  const description = metadata?.description && metadata?.description;
  const image = metadata?.image && metadata?.image;


  return (
    <>
      <Layout>
        <Seo
          title={title}
          description={description}
          image={image}
        />
        <ComponentSelector components={components} />
        <Footer showFooterForm={showFooterForm} />
      </Layout>
    </>
  )
}

export const query = graphql`
  query ($slug: String!) {
    page: allDatoCmsPage(filter: { slug: { eq: $slug } }) {
      nodes {
        slug
        components {
          ... on DatoCmsFocusAreasSection {
            id
            componentId
            headline
            backgroundImage {
              gatsbyImageData
            }
            innerSections {
              headline
              intro
              cards {
                backgroundImage {
                  gatsbyImageData
                }
                link
                title
                tagline
              }
            }
            intro
          }
          ... on DatoCmsInterferenceMap {
            interferenceData
            componentId
          }
          ... on DatoCmsHomeHeroHeader {
            componentId
            headline
            background {
              hasBackgroundImage
              backgroundColor
              image {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            intro
            everyActionFormLink
          }
          ... on DatoCmsFilterBar {
            componentId
            headline
            accentColor
            selectInputs {
              property
              inputLabel
            }
          }
          ... on DatoCmsFeaturedNarrative {
            backgroundImage {
              gatsbyImageData
            }
            componentId
            frontImage {
              gatsbyImageData
            }
            textContent
          }
          ... on DatoCmsPressSubheader {
            componentId
            leftContent
            rightContent
            backgroundImage {
              gatsbyImageData
            }
          }
          ... on DatoCmsHeroHeader {
            componentId
            background {
              backgroundColor
              hasBackgroundImage
              image {
                gatsbyImageData
              }
            }
            hasNav
            hasBreadcrumb
            breadcrumb {
              label
              linkUrl
            }
            headline
            heroSize
            innerNav {
              links {
                linkUrl
                label
                isThisAButton
              }
            }
            intro
          }
          ... on DatoCmsFeatureSlider {
            componentId
            headline
            background {
              backgroundColor
              hasBackgroundImage
            }
            featuredSlides {
              videoSource
              videoLink
              textBorderColor
              intro
              headline
              hasVideo
              featureTag
              button {
                linkUrl
                label
                isThisAButton
              }
              featuredImage {
                featuredImage {
                  gatsbyImageData
                }
              }
            }
          }
          ... on DatoCmsBasicSlider {
            componentId
            relatedProjects {
              title
            }
            relatedFocusAreas {
              title
            }
            relatedResources {
              title
            }
            id
            headline
            cardColor
            cardAlignment
            showIntroText
            introduction
            contentType {
              contentType
            }
            limit
            link {
              isThisAButton
              label
              linkUrl
            }
            background {
              backgroundColor
              hasBackgroundImage
              image {
                gatsbyImageData
              }
            }
            tagToShow
          }
          ... on DatoCmsFormCtaBanner {
            componentId
            headline
            description
            donationForm
            formHeadline
            formLink
            amountButtons {
              amount
            }
            recurringDonationOptions {
              recurringDonation
            }
            image {
              gatsbyImageData(placeholder: BLURRED)
            }
            rightBackground {
              hasBackgroundImage
              backgroundColor
              image {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            leftBackground {
              hasBackgroundImage
              backgroundColor
              image {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          ... on DatoCmsCuratorSection {
            componentId
            curatorFeedId
            socialMediaInfo {
              socialMediaItem {
                socialMediaId
                socialMediaLink
              }
            }
          }
          ... on DatoCmsNarrativeMapSection {
            componentId
            intro
            headline
          }
          ... on DatoCmsAffiliatesMapSection {
            componentId
            id
          }
          ... on DatoCmsAccordion {
            componentId
            contentType {
              contentType
            }
            showFilterBar
            contentFilter {
              accentColor
              headline
              selectInputs {
                inputLabel
                property
              }
            }
          }
          ... on DatoCmsStatisticsRow {
            componentId
            id
            statistics {
              number
              description
            }
          }
          ... on DatoCmsContentGrid {
            componentId
            resultsLimit
            tagToShow
            contentType {
              contentType
            }
            showFilterBar
            contentFilter {
              accentColor
              headline
              selectInputs {
                inputLabel
                property
              }
            }
          }
          ... on DatoCmsNarrativeTextBlock {
            componentId
            textContent
            hasImage
            blockWidth
            highlightColor
            imageAlignment
            blockImage {
              featuredImage {
                gatsbyImageData(placeholder: BLURRED)
                title
              }
            }
          }
          ... on DatoCmsFeatureCta {
            componentId
            hasVideo
            headline
            intro
            videoSource
            videoLink
            featureTag
            featuredImage {
              featuredImage {
                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
              }
            }
            button {
              label
              linkUrl
            }
            background {
              hasBackgroundImage
              backgroundColor
              image {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          ... on DatoCmsAffiliatesMapSection {
            componentId
          }
          ... on DatoCmsBasicCtaBanner {
            componentId
            headline
            description
            cta {
              label
              linkUrl
            }
            imageAlignment
            background {
              hasBackgroundImage
              backgroundColor
              image {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            bannerImage {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          ... on DatoCmsStrategicImperativesGraphicSection {
            componentId
            circleLinks {
              color
              link
              name
            }
            background {
              hasBackgroundImage
              backgroundColor
              image {
                gatsbyImageData
              }
            }
          }
          ... on DatoCmsVideoEmbed {
            componentId
            embedCode
            youtubeOrVimeo
            imageOverlay
            image {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          ... on DatoCmsFeaturedContentHeroHeader {
            background {
              backgroundColor
              hasBackgroundImage
              image {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            componentId
            headline
            featuredContent {
              boxAlignment
              background {
                backgroundColor
                hasBackgroundImage
                image {
                  gatsbyImageData
                }
              }
              button {
                linkUrl
                label
              }
              hasVideo
              headline
              featureTag
              featuredImage {
                featuredImage {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
              intro
              textBorderColor
              videoLink
              videoSource
            }
          }
          ... on DatoCmsFeaturedContentHero {
            background {
              backgroundColor
              hasBackgroundImage
              image {
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            componentId
            featuredContent {
              boxAlignment
              textBorderColor
              article {
                title
                thematicTags
                slug
                featuredImage {
                  gatsbyImageData(placeholder: BLURRED)
                }
                introText
              }
            }
          }
        }
        pageTitle
        showFooterForm
        metadata {
          description
          title
          image {
            url
          }
          twitterCard
        }
      }
    }
  }
`
